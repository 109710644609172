
import { defineComponent } from "vue";
import ClientPolicyTable from "@/components/individual/ClientPolicyTable.vue";
import ApiService from "@/core/services/ApiService";
import JwtService from "@/core/services/JwtService";
import variables from "@/router/api";

export default defineComponent({
  name: "admin-policy-listing",
  components: {
    ClientPolicyTable,
  },
  data() {
    return {
      policies: [],
    };
  },
  created() {
    //Fetch individual client dashboard data
    if (JwtService.getToken()) {
      ApiService.setHeader();
      ApiService.get(variables.SUPERADMIN_CLIENT_POLICIES_ROUTE)
        .then(({ data }) => {
          //Assign data to variable to bebpassed as a prop
          this.policies = data.data;
        })
        .catch(function(error) {
          variables.toastAlert(error.response.data.error, "error");
        });
    }
  },
});
