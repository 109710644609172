
import { defineComponent, onMounted } from "vue";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import Policies from "@/components/migration/Policies.vue";

export default defineComponent({
  name: "broker-policy-migration-list",
  components: { Policies },
  setup() {
    onMounted(() => {
      setCurrentPageBreadcrumbs("Existing Policies", ["Policy Migration"]);
    });
  },
  created() {
    //Set page title
    document.title =
      "Broker: Existing Policies List | " + process.env.VUE_APP_TITLE;
  },
});
